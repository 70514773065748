body {
  margin: 0;
  font-family: var(--primary-font), var(--secondary-font), 'Helvetica';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --text: #050505;
  --main: #eee;
  --off-black: #eee;
  --grey: #ccc;
  --accent: #66f;
  --highlight: #e44;
  --blue: #00f;
  --light-grey: #eee;
  --dark-grey: #444;
  --red: #f44;
  --yellow: #ff4;
  --green: #0f0;
  --secondary-font: 'Dosis';
  --primary-font: 'PT Sans Narrow';
  
  background-color: var(--main);
  color: var(--text);
}
