div.text {
    font-size: 1.4em;
}
div.title {
    color: var(--dark-grey);
}

label.term {
    color: var(--dark-grey);
}

button.reset {
    color: var(--black);
}

button.submit {
    color: var(--white);
}

div.actions {
    padding: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

input.chadified {
    background-color: var(--light-grey);
    color: var(--grey);
}

div.complete {
    white-space: pre-wrap;
}