.photosSelectContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}
.photoSelect {
    width: 100px;
    height: 100px;
    object-fit: cover;
}
.photoSelect:hover {
    cursor: pointer;
    opacity: 0.7;
}
.photoSelect.selected {
    border: 3px solid #000;
}
.page {
    padding: 1em;
}
.page:hover {
    cursor: pointer;
    opacity: 0.7;
}
.invalidFormat {
    opacity: .2;
}
.deleteIcon:hover {
    cursor: pointer;
    opacity: 0.7;
}
.controls {
    padding: 1em;
}