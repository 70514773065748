.fb {
    max-width: 8%;
}

.summary {
    width: 30%;
    margin: auto;
}

.bandImage {
    max-width: 100%;
}

@media only screen and (max-width: 600px) {
    .fb {
        max-width: 20%;
    }
    .summary {
        width: 90%;
    }
}