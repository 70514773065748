.Header {
    margin: 0;
    z-index: 100;
}

.logo {
    font-family: 'Monoton';
    padding: 0;
    margin: 0;
}

.selected {
    text-decoration: underline!important;
    text-decoration-color: var(--highlight)!important;
} 

.listNav {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.burgerNavLinks > a {
    display: block;
    font-size: 2em;
}

.burgerNav > div {
    margin: auto;
}

.link {
    text-decoration: none;
    list-style-type: none;
}

a {
    text-decoration: none;
    color: var(--white);
}

a:hover {
    cursor: pointer;
}

@media (max-width: 600px) {
    .listNav {
        display: none;
    }
}

@media (min-width: 600px) {
    .burgerNav {
        display: none;
    }
}