.label {
    padding-right: 1em;
    width: 10em;
    display: inline-block;
}
.request {
    padding-top: 1em;
}
.directions {
    padding: 1em;
}
.pastRequests:hover {
    opacity: .5;
}
.pastRequests {
    padding: 1em;
}