.App {
  text-align: center;
}

.sticky {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: var(--main);
  z-index: 10;
}

.body {
  max-width: 100%;
  overflow: scroll;
}