div.List {
    margin: auto;
    display: inline-block;
    max-width: 100%
}

table.libs {
    overflow-x: auto;
    max-width: fit-context;
    display: block;
    max-width: -moz-fit-content;
    /* white-space: nowrap; */
    margin: auto;
}

table.libs>thead>tr>th {
    background-color: var(--light-grey);
}

table.libs>tbody>tr:hover {
    background-color: var(--light-grey);
}

td.user,td.created {
    font-size: .6em
}

button.play {
    color: var(--white);
}

button.play:hover {
    opacity: .5;
}

button.edit {
    color: var(--red);
}

div.status {
    color: var(--white);
    background-color: var(--green);
}

div.instructions {
    width: 80%;
    margin: auto;
}