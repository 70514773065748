.coordinates {
    background-color: red;
    width: .6em;
    height: .6em;

    border-radius: .3em;
}
table.calendar {
    margin: auto;
    border-collapse: collapse;
}

td,th {
    padding: 1em;
}


.eventRow:hover {
    background-color: var(--grey);
}

.oblique {
    font-style: oblique;
}

.bold {
    font-weight: 800;
}

.hidden {
    display: none;
}

@media only screen and (max-width: 600px) {
    div.calendar {
        width: 100%;
    }
}